import React from 'react'
import { Link } from 'gatsby'
import {
    WhatsappIcon,
    PhoneIcon,
    LocationIcon,
    EmailIcon,
    FacebookIcon,
    InstagramIcon,
    ClockIcon,
    DiamondIcon
} from '../../icons'
import { Layout } from "../../components/layout/layout"

const CONTACT_METHODS = [
    {
        name: 'WhatsApp',
        note: '(Recommended)',
        icon: <WhatsappIcon fill={'#fff'} width={70} height={70} />,
        class: 'bg-green-dark text-white',
        link: 'https://wa.me/441623571691',
        external: true,
        openNew: true
    },
    {
        name: 'By Phone',
        note: '',
        icon: <PhoneIcon fill={'#000'} width={70} height={70} />,
        class: 'bg-gray-sm text-black',
        link: 'tel:01623571691',
        external: true,
        openNew: false
    },
    {
        name: 'Visit Us',
        note: '',
        icon: <LocationIcon fill={'#000'} width={70} height={70} />,
        class: 'bg-gray-sm text-black',
        link: 'https://maps.app.goo.gl/ZnJbG5KwJawz7vv99',
        external: true,
        openNew: true
    },
    {
        name: 'By Email',
        note: '',
        icon: <EmailIcon fill={'#000'} width={70} height={70} />,
        class: 'bg-gray-sm text-black',
        link: 'mailto:mansfield@pierceofart.com',
        external: true,
        openNew: false
    },
    {
        name: 'Facebook',
        note: '',
        icon: <FacebookIcon fill={'#fff'} width={70} height={70} />,
        class: 'bg-dark-blue text-white',
        link: 'https://facebook.com/pierceofartuk',
        external: true,
        openNew: true
    },
    {
        name: 'Instagram',
        note: '',
        icon: <InstagramIcon fill={'#fff'} width={70} height={70} />,
        class: 'bg-gradient-to-br from-gradient-start via-gradient-stop to-gradient-end text-white',
        link: 'https://instagram.com/pierceofart',
        external: true,
        openNew: true
    },
    {
        name: 'Appintments',
        note: 'Book Here',
        icon: <ClockIcon fill={'#fff'} width={70} height={70} />,
        class: 'bg-red-primary text-white',
        link: '/book-now-mansfield',
        external: false,
        openNew: false
    },
    {
        name: 'Shop',
        note: '',
        icon: <DiamondIcon fill={'#fff'} width={70} height={70} />,
        class: 'bg-primary text-white',
        link: '/body-piercing-jewellery',
        external: false,
        openNew: false
    }
]

const HocLink = (props) => {
    return props.external ? <a href={props.link} target={props.openNew ? '_blank' : '_top'} rel="noreferrer">{props.children}</a> : <Link to={props.link}>{props.children}</Link>
}

export default function ContactPage() {
    return (
      <Layout>
            <section className="px-2 py-4">
                <div className="px-4 text-base">
                    <h1 className="text-2xl pt-4 pb-3 font-medium">Get in Touch</h1>
                    <p className="py-2 leading-5">Want to reach out to us? We'd love to hear from you.</p>
                    <p className="py-2 leading-5">We've made it really easy. Just click on your preferred method below.</p>
                    <div className="py-2">
                        <p className="leading-5">Pierce of Art (Mansfield)</p>
                        <p className="leading-5">2, Queens Walk, Mansfield NG18 1JX</p>
                    </div>
                </div>
            </section>
            <section className="px-2 pb-4">
                <div className="grid grid-cols-2 gap-1">
                {
                    CONTACT_METHODS.map((method, index) => (
                        <HocLink link={method.link} external={method.external} openNew={method.openNew} key={index}>
                            <div className={`method-item flex flex-col items-center h-full justify-between p-4 ${method.class}`}>
                                <div className='text-center mb-8'>
                                    <h3 className="text-xl font-semibold">{method.name}</h3>
                                    { method.note && <div className="text-sm">{method.note}</div> }
                                </div>
                                <div>
                                    { method.icon }
                                </div>
                            </div>
                        </HocLink>
                    ))
                }
                </div>
            </section>
      </Layout>
    )
}
  
